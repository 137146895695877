
@font-face {
  font-family: "Stag-Medium";
  src: url(./assets/fonts/Stag-Medium-Web.woff) format("woff");
}
@font-face {
  font-family: "Stag";
  src: url(./assets/fonts/Stag-Medium-Web.woff) format("woff");
}

@font-face {
  font-family: "Inter-Medium";
  src: url("./assets/fonts/Inter-Medium.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: "Inter-Regular";
  src: url("./assets/fonts/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url("./assets/fonts/Inter-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Inter-Bold";
  src: url("./assets/fonts/Inter-Bold.woff") format("woff");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

body svg {
  overflow: hidden !important;
}

body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

#root {
  height: auto;
  min-height: 100%;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*@font-face {*/
/*  font-family: 'Stag-Medium';*/
/*  src: url(./assets/fonts/Stag-Medium.otf) format('opentype');*/
/*}*/
@font-face {
  font-family: "Stag-Medium";
  src: url(./assets/fonts/Stag-Medium-Web.woff) format("woff");
}
/*@font-face {*/
/*  font-family: 'Stag';*/
/*  src: url(./assets/fonts/Stag-Medium.otf) format('opentype');*/
/*}*/
@font-face {
  font-family: "Stag";
  src: url(./assets/fonts/Stag-Medium-Web.woff) format("woff");
}

@font-face {
  font-family: "Inter-Medium";
  src: url("./assets/fonts/Inter-Medium.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: "Inter-Regular";
  src: url("./assets/fonts/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url("./assets/fonts/Inter-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Inter-Bold";
  src: url("./assets/fonts/Inter-Bold.woff") format("woff");
}


#splash-screen{
  position: fixed;
  height: 160px;
  width: 450px;
  overflow: hidden;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
#logo-box{
  position: absolute;
  height: 100px;
  width: 250px;
  overflow: hidden;
  margin: auto 0 auto auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#gears-box{
  height: 160px;
  width: 160px;
  overflow: hidden;
}

.Caps input {
  text-transform: uppercase;
}
.QuoteContractPeriodDate{
  color: #000000;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.QuoteContractPeriodDate .MuiButtonBase-root{
  padding: 0 0;
}
.MultiIconGroup{
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.MultiIconGroup .MuiIconButton-root:hover {
  background-color: transparent;
}
.MultiIcon{
}
.MultiIconDiv{
  box-sizing: border-box;
  height: 17.5px;
  width: 1px;
  border: 1px solid #B0B7C3;
  margin-left: 7px;
}
body{
  overflow-x: hidden;
}
@media (max-width: 1440px) {
  body{
    overflow-x: scroll;
  }
}
@media (max-width: 1024px) {
  body{
    overflow-x: scroll;
    overflow-y: scroll;
    height: 100vh;
    width: 100vw;
  }
  .QuotePopupGroup{
    word-break: break-word!important;
    text-align: left!important;
  }
}

@media (max-width: 768px) {
  body{
    overflow-x: hidden;
    /* overflow-y: hidden; */
    height: 100vh;
    width: 100vw;
  }
}

a:visited {
  color: #0000EE;
  text-decoration: none;
}
a:link {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

/* select  global ... */
.MuiPopover-root .MuiPaper-elevation8 {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.MuiPopover-root .MuiPaper-rounded {
  border-radius: 12px !important;
}

.MuiPopover-root .MuiList-padding {
  padding: 0;
}

.MuiPopover-root .MuiList-root .MuiListItem-button {
  background-color: #fff !important;
}
.MuiPopover-root .MuiList-root .MuiListItem-button:hover {
  background: #003da5 !important;
  color: white !important;
}
.MuiPopover-root .MuiList-root .MuiListItem-button:hover g {
  fill: #fff;
}

.MuiPopover-root .MuiList-root .MuiMenuItem-root {
  padding-top: 0;
  padding-bottom: 0;
  height: 64px;
  color: #0a1f44;
  font-family: Inter, sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 64px;
}
