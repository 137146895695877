.ModalContainerClass {
    top: 30%;
}
.ModalTopBar{
    height: 57.47px;
    border-radius: 20px 20px 0 0;
    background-color: #00C1CC;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 38px;
}
.ModalIcon{
    fill: #ffffff;
    margin-right: 14px;
}

.ModalTitle{
    color: #FFFFFF;
    font-family: Inter-Bold, sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
}

.ModalContent{
    padding-left: 49px;
    padding-right: 52px;
}
.ModalContentNoPadding{

}
.large{
    width: 829px;
}
.small{
    width: 550px;
}

.GeneralPageContainer .ModalContent{
    margin-top: 34px;
}

.ExitContainer .ModalContent{
    margin-top: 70px;
}

@media (max-width: 1024px) {
    .ModalTopBar{
        height: 80px;
        padding-left: 27px;
    }
    .ModalTitle {
        margin-right: 48px;
        line-height: 24px;
    }
    .modalCloseButton svg{
        min-width: 20px!important;
        min-height: 20px!important;
        width: 20px!important;
        height: 20px!important;
    }
    .ModalContent{
        padding-left: 25px;
        padding-right: 24px;
    }
}

.picsPage {
    top: -200px;
}

.picsPage .ModalContent {
    padding-left: 65px;
    padding-right: 65px;
}

